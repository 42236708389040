import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    '&.spaced': {
      justifyContent: 'space-between'
    },
    '&.centred': {
      justifyContent: 'center'
    }
  },
  list: {
    overflow: 'auto',
    height: '100%'
  },
  marginRight: {
    marginRight: theme.spacing()
  },
  grow: {
    flexGrow: 1
  },
  shrink: {
    flexShrink: 1
  },
  centred: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const DefaultRow = ({ classes, height, data, displayProperty }) => {
  if (typeof data !== 'object') {
    return <div className={`${classes.grow} ${classes.centred}`} style={{height}}><Tooltip title={data}><Typography variant='body1' noWrap>{data}</Typography></Tooltip></div>
  }
  if (displayProperty) {
    return <div className={`${classes.grow} ${classes.centred}`} style={{height}}><Tooltip title={data[displayProperty]}><Typography variant='body1' noWrap>{data[displayProperty]}</Typography></Tooltip></div>
  }
  return <div className={`${classes.grow} ${classes.centred}`} style={{height}}>
    {Object.keys(data).map((k, i) => <div key={i} className={`${classes.flexRow} centred`}>
      <Typography variant='body1' className={classes.marginRight} >{k}:</Typography>
      <Typography variant='body2' className={classes.marginRight} >{data[k]}</Typography>
    </div>)}
  </div>
};

const SelectRow = ({ classes, data, idField, checked, updateChecked, children }) => <div className={`${classes.flexRow} spaced`} onClick={() => updateChecked(data)}>
    <Checkbox
      checked={~checked.findIndex(c => (data[idField] || data) === (c[idField] || c))}
      disableRipple
      className={classes.shrink}
    />
    {children}
  </div>

  const AdornedRow = ({ classes, data, children, adornment }) => <div className={`${classes.flexRow} spaced`} >
    {children}
    {adornment(data)}
  </div>

export const ListWidget = ({ title, row = DefaultRow, items, width, selectId, selected, selectUpdate, height, displayProperty, endAdornment }) => {
  const classes = useStyles({height});
  const rowRenderer = (i) => endAdornment
    ? <AdornedRow classes={classes} data={i.data} adornment={endAdornment}>{row(i)}</AdornedRow>
    : selectUpdate // if they have given a function to update Selected assume they want to use select row
      ? <SelectRow classes={classes} data={i.data} idField={selectId} checked={selected} updateChecked={selectUpdate} >{row(i)}</SelectRow>
      : row(i);
  const listStyle = { width: '100%' };
  height && (listStyle.height = height);
  return <div className={classes.list} style={{ width }}>
    {title ? <Typography variant='h6' align='center'>{title}</Typography> : null}
    <List>
      {items && items.map((i, idx) => <ListItem key={idx} style={listStyle}>{rowRenderer({ data: i, height, classes, index: idx, displayProperty })}</ListItem>)}
    </List>
  </div>
};

export default ListWidget;