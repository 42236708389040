import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const BoolWidget = React.forwardRef(({ label, id, value, updateValue, disabled, ...props }, ref) => {
  return (
    <FormControlLabel
      ref={ref} // Add ref and props for tooltip
      {...props}
      label={label}
      control={<Switch checked={value} onChange={(ev) => updateValue && updateValue(id, ev.target.checked)} disabled={disabled}/>}
    />
  )
});

export default BoolWidget