import React, { useRef, forwardRef } from 'react';

import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Widget from './Widget';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: props => props.rowHeight,
    alignItems: 'center',
    '&.header': {
      top: '0px',
      zIndex: 2,
      position: 'sticky !important',
      background: 'white'
    },
    '&.header-webkit': {
      position: '-webkit-sticky !important'
    }
  },
  cell: {
    height: props => props.rowHeight,
    padding: theme.spacing(0.5)
  }
}));

const DEFAULT_ROW_HEIGHT = 36;
const SELECT_WIDTH = 50;

const SimpleRow = (schema, classes) => ({item}) => {
  return <>
    {schema && schema.current && schema.current.map((s, idx) => {
      const { label, ...widgetVals } = s;
      return <div key={idx} style={{ flexBasis: s.width }} className={classes.cell}><Widget {...widgetVals} value={item[s.id]} /></div>
    })}
  </>
};

const Row = (RowRenderer, classes, headerHeight = 0) => ({ data, index, style }) => {
  const item = data[index];
  return <div key={index} style={{ ...style, top: style.top + headerHeight }} className={classes.row}>
    <RowRenderer item={item} />
  </div>
};

const Header = ({ schema, classes }) => (<>
    {schema && schema.current && schema.current.map((s, idx) => <div key={idx} style={{ flexBasis: s.width }} className={classes.cell}><Typography variant='body1' noWrap>{s.label}</Typography></div>)}
  </>);

const innerElementType = props => forwardRef(({ children, ...rest }, ref) => {
  return (
    <div ref={ref} {...rest} >
      <div className={`${props.classes.row} header header-webkit`}><Header {...props} /></div> {/* props is schema and classes */}
        {children}
    </div>
  )
});

const distributeWidth = (schema, totalWidth, disabled) => {
  const [missing, used] = schema.reduce((acc, cur) => {
    return cur.width ? [ acc[0], acc[1] + cur.width ] : [ [...acc[0], cur], acc[1] ];
  }, [[], 0]);
  const remainingWidth = (totalWidth - used) / missing.length;
  return schema.map(s => ({ ...s, width: s.width ? s.width : remainingWidth, disabled: disabled || s.disabled }));
}

export const TableWidget = ({ items, schema, loadMoreItems, selectEnabled, rowRenderer = SimpleRow, rowHeight, itemCount, scrollTo = 0, header = true, disabled = false }) => {
  const theme = useTheme();
  const classes = useStyles({ rowHeight: (rowHeight || DEFAULT_ROW_HEIGHT) + theme.spacing() });
  const widthSchema = useRef(schema);
  return <div style={{ width: '100%', height: '100%', flex: '1 1 auto' }}>
    <InfiniteLoader
      itemCount={itemCount || items.length}
      isItemLoaded={() => true}
      loadMoreItems={loadMoreItems || (() => Promise.resolve())}
      minimumBatchSize={300}
      threshold={300}
    >
      {({ onItemsRendered, ref }) => (
        <AutoSizer>
          {({ height, width }) => {
            widthSchema.current = distributeWidth(schema, selectEnabled ? width - (SELECT_WIDTH + 12) : width - 12, disabled);
            return <List
              itemData={items}
              ref={ref}
              innerElementType={header ? innerElementType({ schema: widthSchema, classes }) : null}
              height={height}
              itemCount={itemCount || items.length}
              itemSize={index => (rowHeight || DEFAULT_ROW_HEIGHT) + theme.spacing()}
              width={width}
              onItemsRendered={onItemsRendered}
              // layout={horizontal ? 'horizontal' : 'vertical'}
              initialScrollOffset={scrollTo > height ? scrollTo : 0}
            >
              {Row(rowRenderer(widthSchema, classes), classes, header ? (rowHeight || DEFAULT_ROW_HEIGHT) + theme.spacing() : null)}
            </List>
          }}
        </AutoSizer>
      )}
    </InfiniteLoader>
  </div>
};

export default TableWidget;