// https://stackoverflow.com/a/14919494
const sizes = [ 'KB', 'MB', 'GB', 'TB', 'PB' ];
const multiplier = 1024, dp = 2;
export const convertBytes = (bytes) => {
  if (!(bytes || bytes === 0)) {
    return 'Size Unknown';
  }
  if (Math.abs(bytes) < multiplier){
    return bytes + ' B';
  }
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= multiplier;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= multiplier && u < sizes.length - 1);
  return bytes.toFixed(dp) + ' ' + sizes[u];
};

const textHelper = { convertBytes };

export default textHelper;