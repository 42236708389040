import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Chip from '../display/chip';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    flexWrap: 'nowrap',
    '&.Mui-focused': {
      flexWrap: 'wrap'
    }
  },
  input: {
    minWidth: '0 !important',
    padding: 0
  },
  icon: {
    color: props => theme.palette[props.colour === 'secondary' ? 'secondary' : 'primary' ].contrastText || 'white',
    padding: theme.spacing(0.5)
  }
}));

function useCombinedRefs(...refs) {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    })
  }, [refs]);

  return targetRef;
}

const SelectWidget = React.forwardRef(({ label, id, value, multiple, choices, updateValue, disabled, customString, orderable, returnJson, extraAdornment = null, colour = 'primary', ...props }, ref) => {
  const classes = useStyles({colour});
  const innerRef = React.useRef(null);
  const combinedRef = useCombinedRefs(ref, innerRef);
  const getLocalVal = () => !!value ? (returnJson ? JSON.parse(value) : value) : (multiple ? [] : null)
  const [localVal, setLocalVal] = useState(getLocalVal());
  const [width, setWidth] = useState(0);
  
  const extraProps = {};
  orderable && (extraProps.renderTags = (v, getTagProps) => v.map((option, index) => (
    <Chip variant='filled' label={(option && option.name) || option} colour={colour} {...getTagProps({ index })}
      endAdornment={<>
        <IconButton className={classes.icon} onClick={() => reorder(index, index - 1)} disabled={index === 0}><Icon>expand_less</Icon></IconButton>
        <IconButton className={classes.icon} onClick={() => reorder(index, index + 1)} disabled={index === v.length - 1}><Icon>expand_more</Icon></IconButton>
        {extraAdornment && extraAdornment(option, index)}
      </>}
    />
  )));
  const reorder = (idx, to) => {
    localVal.splice(to, 0, localVal.splice(idx, 1)[0]);
    setLocalVal(localVal);
    handleUpdate(null, localVal);
  };
  const handleUpdate = (event, val) => { // with regards to orderable selects, the order of the array is the order of items - no order property
    event && event.preventDefault();
    const retVal = multiple
      ? val.length ? val : null
      : val ? val : null
    updateValue && updateValue(id, returnJson ? JSON.stringify(retVal) : retVal);
  };
  useLayoutEffect(() => {
    const rect = combinedRef.current.getBoundingClientRect();
    setWidth(rect.width);
  }, [ref])
  useEffect(() => {
    setLocalVal(getLocalVal());
  }, [value, multiple]);
  const getOptionLabel = option => typeof option === 'object'
    ? customString ? customString(option) : (option.name || option.Name)
    : option;
  return (
    <Autocomplete
      ref={combinedRef} // Add ref and props for tooltip
      {...props}
      classes={{
        inputRoot: classes.inputRoot,
        input: classes.input
      }}
      id={id}
      limitTags={width && Math.floor(width / 150)}
      size={width <= 200 ? 'small' : 'medium'}
      value={localVal}
      options={choices || []}
      getOptionLabel={getOptionLabel}
      onChange={handleUpdate}
      openOnFocus={true}
      disabled={disabled}
      multiple={multiple || orderable}
      disableCloseOnSelect={multiple || orderable}
      renderInput={params => <TextField {...params} margin='dense' label={label} variant='outlined' fullWidth/>}
      {...extraProps}
    />
  )
});

export default SelectWidget
