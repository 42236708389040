import React from 'react';
import TextField from '@material-ui/core/TextField';

const NumberWidget = React.forwardRef(({ label, id, value, updateValue, disabled, ...props }, ref) => {
  const handleUpdate = (event) => {
    event.preventDefault();
    updateValue && updateValue(id, event.target.value ? +event.target.value : null);
  }
  return (
    <TextField
      ref={ref} // Add ref and props for tooltip
      {...props}
      variant='outlined'
      margin='dense'
      label={label}
      type='number'
      disabled={disabled}
      defaultValue={value}
      fullWidth
      onBlur={handleUpdate}
      onKeyPress={event => event.key && ['Tab', 'Enter'].includes(event.key) && handleUpdate(event)}
    />
  )
});

export default NumberWidget
