import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Widget from './index';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  widgets: {
    display: 'flex',
    width: '100%',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2)
  },
  widget: {
    flexBasis: props => `${props.width}px`,
    textAlign: 'center'
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  control: {
    margin: theme.spacing()
  },
  reporting: {
    width: '90%',
    textAlign: 'center',
    marginLeft: '5%'
  }
}));

export const FormWidget = ({ updateValue, title, schema, disabled, width }) => {
  const classes = useStyles({width});
  return <div className={classes.content}>
    {title && <Typography variant='h4' component='h3'>{title}</Typography>}
    <div className={classes.widgets}>
      {schema.map((f, index) => {
        return <div key={index} className={classes.widget}>
          <Widget {...f} key={index} updateValue={updateValue} disabled={disabled || f.disabled} width={width} />
        </div>
      })}
    </div>
  </div>
};

export default FormWidget