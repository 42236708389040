import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    width: props => props.width,
    alignItems: 'left'
  },
  drop: {
    textAlign: 'center',
    borderRadius: '2px',
    width: '100%',
    '&.hover': {
      background: 'lightgrey'
    },
    '&:hover': { // have as pseudo and real class
      background: 'lightgrey'
    }
  },
  block: {
    display: 'block'
  }
}));

const FileSelectWidget = React.forwardRef(({ label, id, value, updateValue, disabled, url, downloader, thumbnail, width, ...props }, ref) => {
  const [thumbnailDataUrl, setThumbnailDataUrl] = useState(null);
  const classes = useStyles({width});
  const handleUpdate = (event) => {
    if (event.target.files[0]) {
      updateValue && updateValue(id, url ? { name: event.target.files[0].name, url: URL.createObjectURL(event.target.files[0]) } : event.target.files[0]);
    } else {
      handleClear();
    }
  }
  const handleClear = _ => {
    !disabled && updateValue(id, null);
  }
  const handleThumbnailError = () => {
    const reader = new FileReader();
    reader.onload = e => setThumbnailDataUrl(e.target.result);
    reader.onerror = console.error;
    reader.readAsDataURL(value);
  };
  const rootStyle = {
  };
  return (
    // Add ref and props for tooltip 
    <div ref={ref}{...props} className={classes.root} >
      <Typography style={{ marginRight: '4px' }} variant='body1' >{label}</Typography>
      {value
        ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {thumbnail
            ? <img style={{ maxWidth: `calc(${width}px - 50px)` }} id={id} src={thumbnailDataUrl || value} alt={`${label} field image`} onError={handleThumbnailError}/>
            : <Typography id={id} style={{ marginRight: '4px' }} variant='caption'>{value.name || value.FileName || value}</Typography>}
          <Icon color={disabled ? 'disabled' : 'default'} onClick={handleClear} >close</Icon>
          {!value.size && downloader ? <Icon onClick={() => downloader(id, value)} >download</Icon> : null}
        </div>
        : <input
          id={id}
          type='file'
          key={value}
          disabled={disabled}
          onChange={handleUpdate}
        />}
    </div>
  )
});

const FileDropWidget = React.forwardRef(({ label, id, value, updateValue, disabled, url, width, ...props }, ref) => {
  const classes = useStyles({width});
  const handleDrop = ev => {
    ev.preventDefault();
    const files = [];
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          files.push(file);
        }
      }
    } else if (ev.dataTransfer.files) {
      files = ev.dataTransfer.files;
    }
    ev.target.classList.remove('hover');
    updateValue(id, files.map(f => url ? { name: f.name, url: URL.createObjectURL(f) } : f));
  };
  const fileNames = value && value.length && value.map(v => v.name).join('; ');
  return (
    // Add ref and props for tooltip 
    <div key={id} ref={ref} {...props} className={classes.root}>
      {label ? <Typography variant='body1' >{label}</Typography> : null}
      <div
        onDrop={!disabled && handleDrop}
        className={classes.drop}
        onDragEnter={ev => ev.target.classList.add('hover')}
        onDragLeave={ev => ev.target.classList.remove('hover')}
        onDragOver={ev => ev.preventDefault()}
      >
        {fileNames ? <Tooltip title={fileNames}><Typography className={classes.block} variant='caption' noWrap>{fileNames}</Typography></Tooltip> : <Icon>folder</Icon>}
      </div>
    </div>
  )
});

const FileWidget = ({ drop, ...props }) => {
  return drop
    ? <FileDropWidget {...props} />
    : <FileSelectWidget {...props} />
};

export default FileWidget;
