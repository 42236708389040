import React, { useState } from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';


const isValidDate = d => d instanceof Date && !isNaN(d);

const DateWidget = React.forwardRef(({ label, id, value, updateValue, disabled, ...props }, ref) => {
  const [selectedDate, handleDateChange] = useState(value ? new Date(value) : null);
  const handleUpdate = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (!selectedDate) {
      updateValue && updateValue(id, null);
      return;
    }
    let newDate;
    if (isValidDate(selectedDate)) {
      newDate = selectedDate;
    } else {
      const [date, time] = selectedDate.toString().split('_').join('0').split(' ');
      const dateParts = date.split('/');
      const timeParts = time.split(':');
      newDate = new Date(
        dateParts[2],
        dateParts[1] === '00' ? 0 : dateParts[1] - 1,
        dateParts[0] === '00' ? 1 : dateParts[0],
        timeParts[0], timeParts[1]
      );
    }
    console.log(selectedDate, selectedDate.toString().split('_').join('0'), newDate);
    updateValue && updateValue(id, newDate ? newDate.toISOString() : null);
    handleDateChange(newDate)
  };
  return (
    <KeyboardDateTimePicker
      ref={ref} // Add ref and props for tooltip
      {...props}
      clearable
      inputVariant='outlined'
      margin='dense'
      label={label}
      disabled={disabled}
      fullWidth
      onError={console.error}
      value={selectedDate}
      onBlur={handleUpdate}
      onChange={(d, s) => console.log(d, s) || handleDateChange(isValidDate(d) ? d : s)}
      format='dd/MM/yyyy HH:mm'
    />
  )
});

export default DateWidget
