import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { ListWidget, DefaultRow } from './';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(),
    '&>div': {
      padding: theme.spacing(),
      width: '50%'
    },
    '&>button': {
      padding: theme.spacing(),
      width: '50px'
    },
    '&.transfer': {
      width: '100%',
      flexDirection: 'row'
    }
  },
  transfer: {
    display: 'flex',
    flexDirection: 'row',
    height: props => props.height
  },
  actions: {
    width: '10%',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 1,
    justifyContent: 'center'
  },
  paper: {
    height: '100%',
    width: '45%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '&.actions': {
      width: '64px',
      '&>button': {
        padding: `0 ${theme.spacing(0.5)}px`
      }
    }
  }
}));

const getId = data => data.id || data;
const not = (listA, listB) => listA.filter(a => !~listB.findIndex(b => getId(a) === getId(b)));
const intersection = (listA, listB) => listA.filter(a => ~listB.findIndex(b => getId(a) === getId(b)));

export const TransferList = ({ options, values = [], updateValue, rowHeight = 50, height = 300, width = 300, side = 'left', displayProperty, activeRow, choiceRow }) => {
  const left = side === 'left'
  const classes = useStyles({ height });
  const [selected, setSelected] = useState([]);
  const updateSelected = data => ~selected.findIndex(s => getId(data) === getId(s))
    ? setSelected(selected.filter(s => getId(data) !== getId(s)))
    : setSelected([ ...selected, data ]);
  const moveCombine = () => {
    const toCombine = not(selected, values); // the selected docuents that aren't in the values
    updateValue([ ...new Set([ ...values, ...selected ]) ]);
    setSelected(not(selected, toCombine));
  }
  const moveSeparate = () => {
    const toSeparate = intersection(values, selected);
    updateValue(not(values, selected));
    setSelected(not(selected, toSeparate));
  }

  return <div className={classes.transfer}>
    <Paper className={classes.paper}>
      <ListWidget
        selectId='id' selectUpdate={updateSelected} selected={selected}
        items={left
          ? not(options, values)
          : values
        }
        width={width}
        row={left ? choiceRow : activeRow}
        height={rowHeight}
        displayProperty={displayProperty}
      />
    </Paper>
    <div className={classes.actions}>
      <IconButton onClick={() => updateValue(options)}><Icon>{left ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left'}</Icon></IconButton>
      <IconButton onClick={() => moveCombine()}><Icon>{left ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}</Icon></IconButton>
      <IconButton onClick={() => moveSeparate()}><Icon>{left ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}</Icon></IconButton>
      <IconButton onClick={() => updateValue([])}><Icon>{left ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right'}</Icon></IconButton>
    </div>
    <Paper className={classes.paper}>
      <ListWidget
        selectId='id' selectUpdate={updateSelected} selected={selected}
        items={left
          ? values
          : not(options, values)
        }
        width={width}
        row={left ? activeRow : choiceRow}
        height={rowHeight}
        displayProperty={displayProperty}
      />
    </Paper>
  </div>
};

const OrderableRow = (side, origClasses, reorder) => row => {
  return <div className={origClasses.rowContainer}>
    <div className={`${origClasses.rowContainer} actions`}>
      <IconButton onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); reorder(side, row.index); }}><Icon>keyboard_arrow_up</Icon></IconButton>
      <IconButton onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); reorder(side, row.index, true); }}><Icon>keyboard_arrow_down</Icon></IconButton>
    </div>
    <DefaultRow {...row} />
  </div>
}

export const MappingList = ({ leftOptions, leftDisplayProperty, rightOptions, rightDisplayProperty, values, updateValue, saveName = 'Save' }) => {
  const classes = useStyles();
  const [startLeftValues, startRightValues] = values || [[],[]];
  const [leftValues, setLeftValues] = useState(startLeftValues);
  const [rightValues, setRightValues] = useState(startRightValues);
  const reorder = (side, index, down) => {
    const vals = side === 'left' ? [ ...leftValues ] : [ ...rightValues ];
    vals.splice((down ? index + 1 : index - 1), 0, vals.splice(index, 1)[0]);
    side === 'left'
      ? setLeftValues(vals)
      : setRightValues(vals);
  };
  const handleSubmit = () => {
    if (!((rightValues && rightValues.length) === (leftValues && leftValues.length)))
      return alert("There are uneven numbers selected from either list. Please make it a one to one mapping");
    updateValue([leftValues, rightValues]);
  };
  return <div className={classes.root}>
    <div className={`${classes.root} transfer`}>
      <Paper>
        <TransferList
          options={leftOptions}
          values={leftValues}
          updateValue={setLeftValues}
          activeRow={OrderableRow('left', classes, reorder)}
          displayProperty={leftDisplayProperty}
        />
      </Paper>
      <Paper>
        <TransferList
          options={rightOptions}
          values={rightValues}
          updateValue={setRightValues}
          activeRow={OrderableRow('right', classes, reorder)}
          side='right'
          displayProperty={rightDisplayProperty}
        />
      </Paper>
    </div>
    <Button variant='contained' onClick={handleSubmit}>{saveName}</Button>
  </div>
};

export default MappingList;