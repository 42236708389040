import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createTheme, ThemeProvider } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  palette: {
      primary: {
          main: '#3d4977'
      },
      secondary: {
          main: '#7079AC'
      },
      error: {
        main: 'rgb(178, 34, 34)',
        text: 'rgb(178, 34, 34)'
      }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        {/* <Provider store={store}> */}
          <App />
        {/* </Provider> */}
      </ThemeProvider>
      </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();