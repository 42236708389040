import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: theme.spacing(4),
    borderRadius: theme.spacing(2),
    padding: `0 ${theme.spacing(0.5)}px`,
    margin: theme.spacing(0.5),
    background: props => theme.palette[props.colour === 'secondary' ? 'secondary' : 'primary' ].main,
    color: props => theme.palette[props.colour === 'secondary' ? 'secondary' : 'primary' ].contrastText || 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing()
  },
  delete: {
    color: props => theme.palette[props.colour === 'secondary' ? 'secondary' : 'primary' ].contrastText || 'white',
    padding: theme.spacing(0.5)
  }
}));

export const Chip = ({ label, colour, onDelete, endAdornment, ...props }) => {
  const classes = useStyles({colour});
  return <div {...props} className={classes.chip}>
    <span className={classes.label}>{label}</span>
    {endAdornment}
    <IconButton className={classes.delete} onClick={onDelete}><Icon>close</Icon></IconButton>
  </div>
};

export default Chip;