import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import sizeHelper from '../helpers/sizeHelpers';

const TextWidget = React.forwardRef(({ label, id, value, updateValue, disabled, adornment, rows = 1, hyperlink, size, ...props }, ref) => {
  const handleUpdate = (event) => {
    event.preventDefault();
    updateValue && updateValue(id, event.target.value ? event.target.value : null);
  }
  const saveKeys = ['Tab'];
  rows <= 1 && saveKeys.push('Enter');
  return hyperlink
    ? <>
      <Typography variant='body' style={{ marginRight: 8 }}>{label}</Typography>
      <Link href={value} target='_blank' rel='noreferrer' >
        {value}
      </Link>
    </>
    : <TextField
      ref={ref} // Add ref and props for tooltip
      {...props}
      key={value}
      variant='outlined'
      margin='dense'
      label={label}
      disabled={disabled}
      defaultValue={size ? sizeHelper.convertBytes(value) : value}
      fullWidth
      onBlur={handleUpdate}
      multiline={rows>1}
      minRows={rows}
      maxRows={4}
      onKeyPress={event => event.key && saveKeys.includes(event.key) && handleUpdate(event)}
      InputProps={{ endAdornment: adornment }}
    />
});

export default TextWidget
