import React, { useState } from 'react';
import JSONInput from 'react-json-editor-ajrm/es/index';
import locale from 'react-json-editor-ajrm/locale/en';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Widget, { FormWidget } from '../index';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: props => props.collapsedIcon ? 'row' : 'column',
    width: props => props.width,
    justifyContent: 'center'
  },
  form: {
    width: props => (props.collapsedIcon || props.array) ? 'auto' : '100%',
    textAlign: 'center',
    alignSelf: props => props.array ? 'center' : 'inherit'
  },
  arrayContainer: {
    width: props => props.width,
    display: 'flex',
    flexDirection: 'row'
  },
  arraySelect: {
    width: '100%'
  },
  dialog: {
    height: '100%'
  },
  doneIcon: {
    margin: 'auto 0'
  },
}));

const FormJson = ({ classes, label, id, schema, value, updateValue, width, disabled, open, setOpen, returnObject, formSubmit, collapsedName, icon = 'launch' }) => {
  const [schemaWithVals, setSchemaWithVals] = useState(schema.map(s => ({ ...s, value: (value && value[s.id]) || null })))
  const handleUpdate = (id, val) => {
    setSchemaWithVals(schemaWithVals.map(s => s.id === id ? { ...s, value: val } : s));
  };
  return <>
    <div className={classes.form}>{collapsedName ? <Button variant='contained' onClick={() => setOpen(!open)}>{collapsedName}</Button> : <IconButton onClick={() => setOpen(!open)}><Icon>{icon}</Icon></IconButton>}</div>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='json-form-dialog-title'
      fullWidth
      maxWidth='xl'
      className={classes.dialog}
      PaperProps={{ style: { height: '80%'} }}
    >
      <DialogTitle id='json-form-dialog-title'>{label}</DialogTitle>
      <FormWidget schema={schemaWithVals} width={width} updateValue={handleUpdate} disabled={disabled}/>
      <DialogActions>
        <Button variant='contained' color='secondary' onClick={() => setOpen(false)}>Cancel</Button>
        <Button variant='contained' color='secondary'
          onClick={() => {
            const obj = schemaWithVals.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.value }), {});
            updateValue && updateValue(id, returnObject ? obj : JSON.stringify(obj));
            formSubmit && formSubmit(id, returnObject ? obj : JSON.stringify(obj));
            setOpen(false);
          }}
        >Save</Button>
      </DialogActions>
    </Dialog>
  </>
};

const JsonArrayWidget = ({classes, id, open, setOpen, label, schema, value, updateValue, width, returnObject }) => {
  const [arrVal, setArrVal] = useState(value || []);
  const [updateExisting, setUpdateExisting] = useState(null);
  const newUpdate = (_, val) => {
    const update = [ ...arrVal, val];
    setArrVal(update);
    updateValue(id, returnObject ? update : JSON.stringify(update));
  };
  const existingUpdate = (idx, val) => {
    const update = arrVal.map((v, i) => i === idx ? val : v)
    setArrVal(update);
    updateValue(id, returnObject ? update : JSON.stringify(update));
  };
  const namedSchema = schema.some(s => s.id === 'name') ? schema : [ {id: 'name', label: 'Name' }, ...schema ]
  return <div className={classes.arrayContainer}>
      <Widget 
        className={classes.arraySelect}
        type='select'
        id={`${id}-select`}
        orderable
        multiple
        choices={arrVal}
        value={arrVal}
        updateValue={(_, val) => {
          setArrVal(val);
          updateValue(id, returnObject ? val : JSON.stringify(val));
        }}
        extraAdornment={(opt, idx) => <IconButton onClick={() => setUpdateExisting({idx, value: opt})}><Icon>edit</Icon></IconButton>}
      />
      <FormJson key={Date.now()} classes={classes} open={open} setOpen={setOpen} id={id} label={label} schema={namedSchema} updateValue={newUpdate} width={width} returnObject={true} icon={'add'}/>
      {updateExisting && !isNaN(updateExisting.idx) ? <FormJson classes={classes} open={!isNaN(updateExisting.idx)} setOpen={() => setUpdateExisting(null)} id={updateExisting.idx} label={label} schema={namedSchema} value={updateExisting.value} updateValue={existingUpdate} width={width} returnObject={true} /> : null}
    </div>
};

const JsonWidget = React.forwardRef(({ label, id, value, updateValue, disabled, adornment, width, placeholder, schema, returnObject, collapsedIcon, collapsedName, array, formSubmit, ...props }, ref) => {
  const classes = useStyles({ width, collapsedIcon, array })
  const [open, setOpen] = useState(false);

  const handleUpdate = (content) => {
    if (!content.error) {
      updateValue && updateValue(id, returnObject ? content.jsObject : content.json);
    }
  };
  const val = (value && typeof value === 'string' ? JSON.parse(value) : value) || (placeholder && JSON.parse(placeholder));
  return array
    ? <JsonArrayWidget classes={classes} open={open} setOpen={setOpen} id={id} label={label} schema={schema} value={val} updateValue={updateValue} width={width} returnObject={returnObject}/>
    : (<div ref={ref} {...props} className={classes.root}>
      {!(collapsedIcon || collapsedName) ? <><Typography variant='caption'>{label}</Typography>
      <JSONInput
        id={id}
        onBlur={handleUpdate}
        height={200}
        width={width}
        locale={locale}
        viewOnly={disabled}
        colors={{ background: '#3d4977' }}
        placeholder={val}
        style={{ container: { borderRadius: '4px', textAlign: 'left' } }}
      /></> : val ? <Icon className={classes.doneIcon}>done</Icon> : null}
        {schema && <FormJson classes={classes} open={open} setOpen={setOpen} id={id} label={label} schema={schema} value={val} disabled={disabled} updateValue={updateValue} width={width} returnObject={returnObject} collapsedName={collapsedName} icon={collapsedIcon} formSubmit={formSubmit} />} 
      </div>
    )
});

export default JsonWidget
