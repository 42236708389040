import { ulid } from 'ulid';

export const getSchema = async (schemaId) => {
  if (!schemaId || !['mercedes', 'mastercard'].includes(schemaId)) {
    throw Error('Unknown Class Action');
  }
  const resp = await fetch(`${schemaId}Class.json`);
  return await resp.json();
};

export const submitForm = async (classId, raw) => {
  console.log(raw);
  const { data, files } = raw.reduce((acc, cur) => {
    const { cData, cFiles } = cur.fields.reduce((a, c) => {
      if (c.type === 'file') {
        const fs = c.drop ? c.value : [ c.value ];
        return { ...a, cFiles: [ ...a.cFiles, ...fs ]};
      }
      return { ...a, cData: { ...a.cData, [c.id]: c.value }};
    }, { cData: {}, cFiles: [] });
    return { data: { ...acc.data,  ...cData }, files: [ ...acc.files, ...cFiles ] }
  }, { data: {}, files: [] });

  const custodianId = ulid();
  var blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  blob.name = `${custodianId}.json`; 
  files.push(blob);
  console.log(files);
  for (let f of files) {
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/class-action-url-generator`, {
      method: 'POST',
      headers: { 'Content-Type': "application/json" },
      body: JSON.stringify({
        key: `${custodianId}/${f.name}`,
        contentType: f.type,
        metadata: { custodian: custodianId, 'class-action-id': classId }
      })
    });
    const { signedUrl } = await resp.json();
    await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': f.type,
        'x-amz-meta-custodian': custodianId,
        'x-amz-meta-class-action-id': classId
     },
      body: f
    })
  }
};

const api = {
  getSchema,
  submitForm
};

export default api;