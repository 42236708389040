
import BoolWidget from './BoolWidget'
import DateWidget from './DateWidget'
import NumberWidget from './NumberWidget'
import TextWidget from './TextWidget'
import SelectWidget from './SelectWidget'
import SignatureWidget from './SignatureWidget'
import FileWidget from './FileWidget'
import JsonWidget from './JsonWidget'

export const widgets = { BoolWidget, DateWidget, NumberWidget, TextWidget, SelectWidget, SignatureWidget, FileWidget, JsonWidget };

export default widgets;