import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  closeButton: {
    position:'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  saveButton: {
    position:'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  canvas: {
    border: '1px solid black',
    width: 500,
    height: 300,
    margin: 'auto'
  },
  marginLeft: {
    marginLeft: theme.spacing(0.5)
  },
  input: {
    margin: `0 0 ${theme.spacing(3)}px ${theme.spacing(3)}px`
  }
}));

const SignableDocument = ({ classes, closeDialog, updateValue }) => {
  const canvas = useRef(null);

  const createSignature = () => {
    updateValue && updateValue(canvas.current.getTrimmedCanvas().toDataURL('image/png'));
    closeDialog();
  };
  const updateSignature = file => {
    var reader = new FileReader();
    reader.onloadend = () => {
      canvas.current.clear();
      canvas.current.fromDataURL(reader.result);
    };
    reader.readAsDataURL(file);
  }
  return <>
    <div className={classes.closeButton}><Button onClick={closeDialog}><Icon >close</Icon></Button></div>
    <SignatureCanvas
      ref={canvas}
      canvasProps={{ width: 500, height: 300, className: classes.canvas }}
    />
    <input className={classes.input} id='signature-upload' type='file' accept='image/png' onChange={(ev) => updateSignature(ev.target.files[0])}/>
    <div className={classes.saveButton}>
      <Button variant='contained' color='secondary' onClick={createSignature}><Icon >save</Icon></Button>
    </div>
  </>
};

export const SignatureWidget = React.forwardRef(({ label, id, value, updateValue, disabled, ...props }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  // spread  props and add ref to div for tooltip
  return <div className={classes.root} {...props} ref={ref}>
    {value ? <img alt='signature' src={value} width={200} height={100}/> : null}
    {!disabled && <>
      <Button
        id={`${id}-button`}
        className={`${classes.marginLeft} hide-on-print`}
        disabled={disabled}
        onClick={() => setOpen(true)}
        variant='contained'
        color='secondary'
        size={value ? 'small' : 'medium'}
      >{value ? 'Re-sign here' : label}</Button>
      <Dialog
        id={`${id}-dialog`}
        open={open}
        maxWidth='lg'
        fullWidth
        onClose={closeDialog}
        PaperProps={{
          style: { width: '100%', height: '100%', position: 'asbolute' }
        }}
      >
        <SignableDocument classes={classes} closeDialog={closeDialog} updateValue={(anno) => updateValue(id, anno ? anno : null)}/>
      </Dialog>
    </>}
  </div>
});

export default SignatureWidget;