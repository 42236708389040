import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
// import { changeStage, getCurrentStage, getSuccess, updateValue, getCurrentStageDefinition, getStages, getDisplayMissing, setDisplayMissing } from './stageSlice';
import Widget from '@anexsys/anexsys-react-widgets';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  widgets: {
    display: 'flex',
    width: '100%',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly'
  },
  widget: {
    flexBasis: props => `${props.widgetWidth}px`,
    textAlign: 'center'
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  control: {
    margin: theme.spacing()
  },
  reporting: {
    width: '90%',
    textAlign: 'center',
    marginLeft: '5%'
  }
}))

export const Stage = ({ classes, updateValue, def, downloader, disabled, width }) => {
  return <div className={classes.content}>
    {def && def.fields ? <><Typography variant='h4' component='h3'>{def.name}</Typography>
    <div className={classes.widgets}>
      {def.fields.map((f, index) => {
        const namespacedId = `${def.name}-${f.id}`;
        return <div key={index} className={classes.widget}>
          <Widget {...f} key={namespacedId} updateValue={updateValue} downloader={downloader} disabled={disabled} width={width} />
        </div>
      })}
    </div></> : <CircularProgress />}
  </div>
};

const checkValidValue = (field) => {
  if (!(field.hasOwnProperty('value')))
    return false;
  switch (field.type) {
    case 'select':
      return field.value && field.value.length;
    case 'bool':
      return field.value || field.value === false; // i.e. not null
    default:
      return !!field.value;
  }
}

export const StageStepper = ({ submit, changeStage, stage, stages, widgetWidth, submitName = "Submit", ...props }) => {
  const classes = useStyles({widgetWidth});
  const [displayMissing, setDisplayMissing] = useState(false);

  const missingFields = stages.reduce((acc, cur, index) => {
    const stageMissing = cur.fields.filter(f => f.required && !checkValidValue(f));
    stageMissing.length && (acc = { ...acc, [cur.name]: stageMissing });
    return acc;
  }, {});
  const missing = Object.keys(missingFields || {}).reduce((acc, cur) => {
    return `${acc} ${missingFields[cur].map(f => f.label).join(', ')} fields missing from ${cur}.`;
  }, '');

  const handleSubmit = () => {
    if (missing && !displayMissing) {
      setDisplayMissing(true);
    }
    if (!missing) {
      displayMissing && setDisplayMissing(false);
      submit(stages);
    }
  };
  return <Paper>
    {stages && <>
      {stages.length > 1 ? <Stepper alternativeLabel nonLinear activeStep={stage}>
        {stages.map((step, index) => {
          return (
            <Step key={index} >
              <StepButton
                onClick={() => changeStage(index)}
              >
                {step.name}
              </StepButton>
            </Step>
          );
        })}
      </Stepper> : null}
      <Stage classes={classes} def={stages[stage]} width={widgetWidth} {...props}/> {/* individual stage display */}
      <div className={classes.controls}> {/* controls */}
        {stages.length > 1 ? <>
          <Button className={classes.control} variant='contained' color='primary' onClick={() => changeStage(stage - 1)} disabled={stage === 0} >Previous</Button>
          <Button className={classes.control} variant='contained' color='primary' onClick={() => changeStage(stage + 1)} disabled={stage === (stages.length - 1)} >Next</Button>
          <Button className={classes.control} variant='contained' color='primary' onClick={handleSubmit} disabled={props.disabled} >{submitName}</Button>
        </> : <Button className={classes.control} variant='contained' color='primary' onClick={handleSubmit} disabled={props.disabled} >{submitName}</Button>}
      </div>
      <div className={classes.reporting}>
        {displayMissing && missing && <Typography variant='h4' component='h3' color='error'>{missing}</Typography>}
        {/* {success && <Typography variant='h4' component='h3'>{success}</Typography>} */}
      </div>
    </>}
  </Paper>
}

export default StageStepper;