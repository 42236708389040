import React, { useState } from 'react';
import clsx from 'clsx';
// import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Stage from './features/stage/stage';
// import { setSchema, getError } from './features/stage/stageSlice';

const useStyles = makeStyles(theme => ({
    appBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    appBarLogo: {
        width: '120px',
        height: '40px',
        padding: '4px 12px'
    },
    main: {
        margin: '48px auto 0 auto' // 48px is height of appbar, i.e. logo + padding
    },
    section: {
        flexGrow: 1,
        maxWidth: '97%',
        padding: '3px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

export function App({ title, submitData, appBar = true, widgetWidth = 300, downloader, disabled, delta, updatedRef, submitName, ...props }) {
    const classes = useStyles();
    const [schema, setSchema] = useState(props.schema);
    const [current, changeStage] = useState(0);
    const updateValue = (id, val) => {
        const stages = [ ...schema ];
        const curr = { ...stages[current] }
        stages[current] = { ...curr, fields: curr.fields.map(f => {
            if (f.id === id) {
                const { value, ...field } = f;
                return val === null ? field : { ...field, value: val };
            }
            return f;
        })};
        setSchema(stages);
        updatedRef && (updatedRef.current = true);
    }
    const handleSubmit = data => {
        let retVal = data;
        if (delta) {
            // if we only care about the delta...
            retVal = data.map((s, idx) => {
                const initStage = props.schema[idx];
                return s.fields.reduce((acc, cur) => {
                    const initVal = initStage.fields.find(f => f.id === cur.id);
                    return initVal.value !== cur.value ? { ...acc, fields: [ ...acc.fields, cur ] } : acc;
                }, { name: s.name, fields: [] });
            });
        }
        return submitData(retVal);
    }
    // const dispatch = useDispatch();
    // const error = useSelector(getError);
    // useEffect(() => {
    //     const init = async () => {
    //         console.log('dispatching schema', schema)
    //         dispatch(setSchema(schema));
    //     };
    //     init();
    // }, [dispatch, schema]);
    return (
        <div>
            {appBar ? <AppBar position="static">
                <div className={classes.appBar}>
                    <img src='./Entity_WHT_Small.png' alt='Entity Logo' className={classes.appBarLogo} />
                    <Grid container direction="row" justify="center" alignItems="center">
                        {/* app bar items go here */}
                        {title && <Typography variant='h3' component='h1'>{title}</Typography>}
                    </Grid>
                </div>
            </AppBar> : title ? <Typography variant='h3' component='h1' align='center'>{title}</Typography> : null} {/* If no appBar, set title if there is one, otherwise nothing */}
            <div className={clsx(classes.main, classes.section)}>
                <Stage 
                    submit={(data) => { updatedRef && (updatedRef.current = false); handleSubmit(data); }}
                    updateValue={updateValue}
                    stage={current}
                    changeStage={changeStage}
                    stages={schema}
                    widgetWidth={widgetWidth}
                    downloader={downloader}
                    disabled={disabled}
                    submitName={submitName}
                />
            </div>
        </div>
    );
}

export default App;
