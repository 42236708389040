import React from 'react';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Widget from './Widget';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%'
  }
}));

const folderStyles = makeStyles((theme) => ({
  folder: {
    padding: theme.spacing(0.5),
    marginLeft: props => `${theme.spacing(props.depth)}px`,
    background: 'lightgrey',
    '&.active': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  },
  table: {
    marginLeft: theme.spacing()
  },
  tableRow: {
    '&:hover': {
      background: theme.palette.primary.main,
      colour: theme.palette.primary.contrastText
    }
  },
  tableCell: {
    color: `${theme.palette.secondary.contrastText} !important`,
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  header: {
    display: 'flex'
  },
  sort: {
    display: 'inline',
    cursor: 'pointer',
    '&.sorted': {
      fontWeight: 'bold',
    }
  },
  sortIcon: {
    cursor: 'pointer'
  }
}));

const folder = (schema, onClick, separator, sort, changeSort) => ({ depth, current, node }) => {
  const { path, name, leaf, children, source } = node;
  const Folder = folder(schema, onClick, separator, sort, changeSort);
  const classes = folderStyles({ depth });
  const splitCurrent = current.split(separator);
  const splitPath = path.split(separator);
  const open = splitPath.map((p, idx) => splitCurrent[idx] === p).filter(p => p).length === splitPath.length;

  const sorter = sort
    ? (a, b) => {
      let aVal = a[sort.field], bVal = b[sort.field];
      return sort.descending
        ? (bVal > aVal) - (aVal > bVal)
        : (aVal > bVal) - (bVal > aVal);
    } : () => 0


  return <div className={`${classes.folder} ${current === path ? 'active' : ''}`}>
    <div onClick={() => !leaf && onClick(node)} >
      <Icon>{leaf ? 'label' : open ? 'folder_open' : 'folder'}</Icon><span>{name}</span>
    </div>
    {open && children && (current === path && !leaf
      ? <div className={classes.table}>
        {Object.values(children).length
          ? <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {schema.map(s => <TableCell className={classes.tableCell} key={s.id}>
                    <div className={classes.header}>
                      <Typography className={`${classes.sort} ${sort.field === s.id ? 'sorted' : ''}`} variant='body1' onClick={() => changeSort && changeSort(s.id)}>{s.name}</Typography>
                      {sort && sort.field === s.id ? <Icon className={classes.sortIcon} onClick={() => changeSort && changeSort(s.id)}>{sort.descending ? 'expand_more' : 'expand_less'}</Icon> : null}
                    </div>
                  </TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(children).sort(sorter).map(c => <TableRow className={classes.tableRow} key={c.id} onClick={(ev) => {
                  ev.stopPropagation();
                  !c.leaf && onClick(c);
                }}>
                  {schema.map(s => <TableCell className={classes.tableCell} key={c.id + '~' + s.id}><Widget label={s.name} value={c[s.id]} disabled {...s}/></TableCell>)}
                </TableRow>)}
              </TableBody>
            </Table>
          </TableContainer>
          : <CircularProgress />}
      </div>
      : Object.values(children).map(c => <Folder depth={depth + 1} key={c.id} current={current} node={c} />))}
  </div>
};

export const FolderBrowserWidget = ({ directories, directoryOnClick = () => {}, currentDirectory = '', headers, separator = '/', sort, changeSort }) => {
  const classes = useStyles();

  const Folder = folder(headers, directoryOnClick, separator, sort, changeSort);

  return <div className={classes.root}>
    <Folder depth={1} current={currentDirectory} node={directories} />
  </div>
};

export default FolderBrowserWidget;