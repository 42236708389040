import React, { useState, useEffect } from 'react';
import TV from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import helper from './helpers/nodeHelpers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap'
  }
}));

// const Folder = ({ schema, children }) => {
//   <div>
//     {schema.map(s => (<div>{s.name}</div>))}
//     {children.map(c => {
//       return schema.map(s => (<div>{c[s.name]}</div>))
//     })}
//   </div>
// };

export const TreeView = ({ directories, currentDirectory, setDirectory, getDirectory, separator = '/', sourceRoot = 'root' }) => {
  const classes = useStyles();
  const { generateNodes } = helper(separator, sourceRoot);
  const [nodes, setNodes] = useState(generateNodes(directories));
  useEffect(() => {
    setNodes(generateNodes(directories));
  }, [directories]);
console.log(nodes);
  const renderTree = (node) => {
    const hasChildren = JSON.stringify(node.children || {}) != '{}';
    return <TreeItem
      key={node && node.name}
      nodeId={node && node.name}
      label={node && node.name}
      onClick={!hasChildren && getDirectory ? () => getDirectory(node && node.path) : () => {}}
    >
      {hasChildren ? Object.values(node.children).map(c => renderTree(c)) : null}
    </TreeItem>
  }

  return <div className={classes.root}>
    <TV >
      {renderTree(nodes)}
    </TV>
  </div>
};

export default TreeView;