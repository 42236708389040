import React from 'react';
import Tooltip from '@material-ui/core/Tooltip'
import widgets from './atomic';

const typeMapper = {
  'boolean': 'bool',
  'number': 'number',
  'string': 'text'
};

const getType = value => {
  const typeofVal = typeof value
  return typeofVal !== 'object'
    ? typeMapper[typeofVal]
    : value ? value.type : 'text'
};

const Widget = ({ classes, scheme, type, value, tooltip, ...props}) => {
  let Component, extraProps = {};
  if (props.widget) {
    Component = props.widget;
  } else {
    const typeCheck = type || getType(value)
    switch (typeCheck) {
      case 'bool':
        Component = widgets.BoolWidget;
        break;
      case 'date':
        Component = widgets.DateWidget;
        break;
      case 'number':
        Component = widgets.NumberWidget;
        break;
      case 'select':
        Component = widgets.SelectWidget;
        break;
      case 'json':
        Component = widgets.JsonWidget;
        break;
      case 'thumbnail':
        extraProps = { ...extraProps, thumbnail: true };
        // let fallthrough to file for component
      case 'file':
        Component = widgets.FileWidget;
        break;
      case 'signature':
        Component = widgets.SignatureWidget;
        break;
      case 'size':
        extraProps = { ...extraProps, size: true };
        Component = widgets.TextWidget;
        break;
      case 'hyperlink':
        extraProps = { ...extraProps, hyperlink: true };
        // let fallthrough to text for component
      case 'text':
      default:
        Component = widgets.TextWidget;
    }
  }
  const component = <Component classes={classes} value={value} {...props} {...extraProps} />;
  return tooltip ? <Tooltip title={tooltip}>{component}</Tooltip> : component;
}

export default Widget