import React, { useState, useEffect } from 'react';
import { getSchema, submitForm } from './api';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Wizard from '@anexsys/anexsys-custom-wizard';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    appBarLogo: {
        // width: '120px',
        height: '40px',
        padding: '4px 12px'
    },
    main: {
        margin: '48px auto 0 auto' // 48px is height of appbar, i.e. logo + padding
    },
    section: {
        flexGrow: 1,
        maxWidth: '97%',
        padding: '3px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    loadingModal: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function App() {
    const classes = useStyles();
    const [schema, setSchema] = useState(null);
    const [loading, setLoading] = useState(false);
    const [classId, setClassId] = useState(null);
    const [success, setSuccess] = useState(null);

    console.log(success)
    useEffect(() => {
        const init = async () => {
            try {
                const search = window.location.search;
                const params = new URLSearchParams(search);
                const id = params.get('id');
                setSchema(await getSchema(id));
                setClassId(id);
            } catch (err) {
                setSuccess({ message: (err && err.message) || err, type: 'error' });
            }
        };
        init();
    }, []);
    const handleSubmit = async (data) => {
        setLoading(true);
        try {
            await submitForm(classId, data);
            setSchema(null);
            setSuccess({ message: 'Data has been submitted successfully.' });
            setLoading(false);
        } catch (err) {
            setSuccess({ message: (err && err.message) || err, type: 'error' });
            setLoading(false);
        }
    };
    return (
        <div>
            <AppBar position="static">
                <div className={classes.appBar}>
                    <img src='./SightlineByConsilio-HighRes.png' alt='Consilio Logo' className={classes.appBarLogo} />
                </div>
            </AppBar>
            <div className={`${classes.main} ${classes.section}`}>
                {success && success.message ? <Typography align='center' variant='subtitle1' color={success.type === 'error' ? 'error' : 'primary'}>{success.message}</Typography> : null}
                {schema
                    ? <Wizard 
                        appBar={false}
                        title={schema.name}
                        schema={schema.schema}
                        submitData={handleSubmit}
                    /> : null
                }
            </div>
            {loading
                ? <Modal open={loading}>
                    <div className={classes.loadingModal}>
                        <CircularProgress />
                    </div>
                </Modal> : null}
        </div>
    );
}

export default App;